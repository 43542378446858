@import '../../common/styles/vars.scss';

:local .PageContentBlock {
  width: 100%;
}

:local .AlternateBackground {
  background: $pink-color;
  color: white;
}

:local .Children {
  padding: 1rem;
  @media (min-width: $screen-sm) {
    max-width: 1200px;
    margin: 0 auto;
  }
}