:local .Footer {
  display: flex;
  flex-direction: column;
  background: #9e9e9e;
  color: white;
}

:local .Content {
  display: grid;
  grid-template-columns: .8fr .2fr;
  grid-template-rows: 1fr;
}

:local .Logo {
  width: 100%;
  aspect-ratio: 1 / 1;
  place-self: center;
  padding: 1rem;
}