body {
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* apply a natural box layout model to all elements, but allowing components to change */
html {
    box-sizing: border-box;
}

*, *:before, *:after {
    box-sizing: inherit;
}

