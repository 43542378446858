@import '../../styles/vars.scss';

:local .HeaderLink {
  text-decoration: none;
}

:local .LogoAndTitle {
  display: flex;
  justify-content: center;
  align-items: center;
}

:local .Logo {
  width: 3rem;
  height: 3rem;
  padding: .5rem;

  @media (min-width: $screen-md) {
    width: 5rem;
    height: 5rem;
  }
}

:local .Header {
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(./boekelse_verleiding_vitrine.jpg);
  background-position: center;

  @media (min-width: $screen-md) {
    height: 30rem;
    background-size: auto;
  }
}

:local .Titles {
  background: #EFA3C6;
  border-radius: 8px;
  padding: .5rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

:local .Title {
  font-family: 'Merienda', cursive;
  text-shadow: 2px 2px 2px rgb(64, 64, 64);
  color: white;
  margin: 0;
  font-size: $font-size-ml;

  @media (min-width: $screen-md) {
    font-size: $font-size-xl;
  }
}

:local .Subtitle {
  font-size: $font-size-m;
  color: #696969;
  @media (min-width: $screen-md) {
    font-size: $font-size-l;
  }
}

:local .Image {
  max-height: 400px;
}