$screen-sm: 768px;
$screen-xs: 576px;
$screen-md: 992px;
$screen-lg: 1200px;

$font-size-s: .75rem;
$font-size-m: 1rem;
$font-size-ml: 1.5rem;
$font-size-l: 2rem;
$font-size-xl: 3rem;

$font-weight-bold: 700;
$font-weight-regular: 400;
$font-weight-semibold: 600;

$pink-color: #EFA3C6;