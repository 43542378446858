:local .Container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

:local .Dot {
  width: 1rem;
  height: 1rem;
  border-radius: 100%;
  background-color: red;
}

:local .Text {
  white-space: nowrap;
}