@import '../../styles/vars.scss';

:local .NavBar {
  display: flex;
  height: 3rem;
  background: $pink-color;
}

:local .NavBarWrapper {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;
}

:local .NavBarList {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  list-style: none;
  flex: 1 0 auto;
  justify-content: space-between;

  @media (min-width: $screen-md) {
    justify-content: flex-end;
  }
}

:local .NavBarListItem {
  padding: 0 1rem;
}

:local .NavBarListItemLink {
  color: white;
  &:active, &:focus, &:hover, &:visited, &:-webkit-any-link {
    text-decoration: none;
  }
}
