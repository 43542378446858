@import '../../styles/vars.scss';

:local .Container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: $screen-xs) {
    flex-direction: row;
  }
}

:local .Media {
  display: flex;
  padding: 5px;
  border: 1px solid #CCC;
  box-shadow: 1px 1px 5px #999;
  @media (min-width: $screen-xs) {
    margin-left: 2rem;
  }
}

:local .MediaLeftTop {
  order: -1;
  @media (min-width: $screen-xs) {
    margin-left: 0;
    margin-right: 2rem;
  }
}