@import '../../../common/styles/vars.scss';

:local .Container {
  display: flex;
  flex-direction: column;

  @media (min-width: $screen-md) {
    flex-direction: row;
    justify-content: space-between;
  }
}

:local .Information {
  display: flex;
  flex-direction: column;
}

:local .Text {
  @media (min-width: $screen-md) {
    margin-bottom: 4rem;
  }
}