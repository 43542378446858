@import '../../../../common/styles/vars.scss';

:local .Container {
  width: 300px;
  height: 300px;

  @media(min-width: $screen-md) {
    min-width: 400px;
    min-height: 400px;
  }
}