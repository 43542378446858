:local .Container {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;
  margin-bottom: 1rem;
}

:local .List {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

:local .ListItem {
  line-height: 24px;
}

:local .SocialMediaLink {
  display: block;
  max-width: 250px;
  color: inherit;
  text-decoration: none;
  &:hover, &:active {
    color: inherit;
    text-decoration: none;
  }
}

:local .SocialMediaItemContainer {
  display: flex;
  align-items: center;
}

:local .SocialMediaLogo {
  margin-right: .5rem;
}

:local .FacebookLogo {
  width: 2rem;
  height: 2rem;
}
