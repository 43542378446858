body {
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* apply a natural box layout model to all elements, but allowing components to change */
html {
    box-sizing: border-box;
}

*, *:before, *:after {
    box-sizing: inherit;
}


._28r5aTCyv7c-GvBf4pXNXG {
  margin-top: 0; }

._2Yf_v6IXJ_jwRJPtYNEeqj {
  margin: 0;
  padding: 0; }

._1RvCtZPLHf69at23O6HpqF {
  display: -webkit-flex;
  display: flex; }

._3KXuUufS4jodPHCYP8s-pp {
  -webkit-flex: 1 1 50%;
          flex: 1 1 50%; }

._1R3IdBWimnEdQlenASkSQe {
  -webkit-flex: 1 1 50%;
          flex: 1 1 50%; }

._2UxaOmlqfTfpfH6mETgrhS {
  width: 100%; }

._3GvEVIhdLhI-zuGZXi5Xcv {
  background: #EFA3C6;
  color: white; }

._3FY7QXSZ0xg5IjvtDJ2fkV {
  padding: 1rem; }
  @media (min-width: 768px) {
    ._3FY7QXSZ0xg5IjvtDJ2fkV {
      max-width: 1200px;
      margin: 0 auto; } }

._3rc7f9vTEHXl_v3EYNVY-j {
  text-decoration: none; }

._3UZvRCRiyU0s_ljCMnfwg4 {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }

.mCnAeuyag4QbhodbDCEs3 {
  width: 3rem;
  height: 3rem;
  padding: .5rem; }
  @media (min-width: 992px) {
    .mCnAeuyag4QbhodbDCEs3 {
      width: 5rem;
      height: 5rem; } }

._3kGDe5cIy9Eob3LRvwuTHD {
  height: 10rem;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background-image: url(/static/media/boekelse_verleiding_vitrine.2a114b87.jpg);
  background-position: center; }
  @media (min-width: 992px) {
    ._3kGDe5cIy9Eob3LRvwuTHD {
      height: 30rem;
      background-size: auto; } }

._91e9Yt9tSv-oObnpo00UB {
  background: #EFA3C6;
  border-radius: 8px;
  padding: .5rem 1rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }

.lZUrQ2MeorDc2V6nxISq4 {
  font-family: 'Merienda', cursive;
  text-shadow: 2px 2px 2px #404040;
  color: white;
  margin: 0;
  font-size: 1.5rem; }
  @media (min-width: 992px) {
    .lZUrQ2MeorDc2V6nxISq4 {
      font-size: 3rem; } }

.iZHUfhjje9Ex_S61NBwuJ {
  font-size: 1rem;
  color: #696969; }
  @media (min-width: 992px) {
    .iZHUfhjje9Ex_S61NBwuJ {
      font-size: 2rem; } }

._21H4I4-Nd_dtZdhPn5UOYt {
  max-height: 400px; }

._3XNdsxdlUZmov0wHX9cS5- {
  -webkit-flex-grow: 1;
          flex-grow: 1;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  -webkit-flex-basis: auto;
          flex-basis: auto;
  margin-bottom: 1rem; }

.KDfZ9-8atmI-z9JuKOSCG {
  margin: 0;
  padding: 0;
  list-style-type: none; }

._3-JLE44kpdEGNGGtRyp5xm {
  line-height: 24px; }

.kFkzYN0g_SC0s8-IG9GcK {
  display: block;
  max-width: 250px;
  color: inherit;
  text-decoration: none; }
  .kFkzYN0g_SC0s8-IG9GcK:hover, .kFkzYN0g_SC0s8-IG9GcK:active {
    color: inherit;
    text-decoration: none; }

.YBAVTBVUYbU7ep1kOJk7a {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }

._1Zpk9JmpsgyAi-c4gINV1l {
  margin-right: .5rem; }

._118_mvM5eWCNBPlqvZCkKF {
  width: 2rem;
  height: 2rem; }

._271LmZuRiEsC4aa44BUxtU {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  background: #9e9e9e;
  color: white; }

._1nbGDp9WiDNJImMCjqGA_S {
  display: grid;
  grid-template-columns: .8fr .2fr;
  grid-template-rows: 1fr; }

._3vwqPQeffR7eZqGjromIz5 {
  width: 100%;
  aspect-ratio: 1 / 1;
  place-self: center;
  padding: 1rem; }

._3IaPeeMCe9ozYTzAw-X1Zu {
  display: -webkit-flex;
  display: flex;
  height: 3rem;
  background: #EFA3C6; }

._390iNxcPbDmg-tkVAsklLi {
  max-width: 1200px;
  margin: 0 auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  -webkit-flex-basis: auto;
          flex-basis: auto; }

._1q-li7uehL17ArgFZ8umXv {
  margin: 0;
  padding: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  list-style: none;
  -webkit-flex: 1 0 auto;
          flex: 1 0 auto;
  -webkit-justify-content: space-between;
          justify-content: space-between; }
  @media (min-width: 992px) {
    ._1q-li7uehL17ArgFZ8umXv {
      -webkit-justify-content: flex-end;
              justify-content: flex-end; } }

._12qPmr2iVtOfCH1FF0YvYc {
  padding: 0 1rem; }

.aXr4rq44QhMhP6CVQm5XQ {
  color: white; }
  .aXr4rq44QhMhP6CVQm5XQ:active, .aXr4rq44QhMhP6CVQm5XQ:focus, .aXr4rq44QhMhP6CVQm5XQ:hover, .aXr4rq44QhMhP6CVQm5XQ:visited, .aXr4rq44QhMhP6CVQm5XQ:-webkit-any-link {
    text-decoration: none; }

._2FctLglIgvmBU427MQqJmx {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }
  @media (min-width: 576px) {
    ._2FctLglIgvmBU427MQqJmx {
      -webkit-flex-direction: row;
              flex-direction: row; } }

._1EpoFQyk6t7FMEPecCTAGR {
  display: -webkit-flex;
  display: flex;
  padding: 5px;
  border: 1px solid #CCC;
  box-shadow: 1px 1px 5px #999; }
  @media (min-width: 576px) {
    ._1EpoFQyk6t7FMEPecCTAGR {
      margin-left: 2rem; } }

._1EbMAfhgLsuaKaVA-UfWkL {
  -webkit-order: -1;
          order: -1; }
  @media (min-width: 576px) {
    ._1EbMAfhgLsuaKaVA-UfWkL {
      margin-left: 0;
      margin-right: 2rem; } }

.PkBzCU7c6RQqtqdpMGL4W {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column; }

._2SzZspyDISP9KzF8MhpBzw {
  width: 1rem;
  height: 1rem;
  border-radius: 100%;
  background-color: red; }

._11E_xD8SM-oWMVDRwi7Yo_ {
  white-space: nowrap; }

._13PpomPw-hAfy4iARp-8IL {
  width: 300px;
  height: 300px; }
  @media (min-width: 992px) {
    ._13PpomPw-hAfy4iARp-8IL {
      min-width: 400px;
      min-height: 400px; } }

._3PuX7fG_C7p7zYJCy1Bwqr {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }
  @media (min-width: 992px) {
    ._3PuX7fG_C7p7zYJCy1Bwqr {
      -webkit-flex-direction: row;
              flex-direction: row;
      -webkit-justify-content: space-between;
              justify-content: space-between; } }

.lNMY57U4LJwrBV-Brx7Q0 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }

@media (min-width: 992px) {
  ._3TKbbNjKphZsbPylMMYIFS {
    margin-bottom: 4rem; } }

