:local .OpeningHours {

}

:local .Title {
  margin-top: 0;
}

:local .List {
  margin: 0;
  padding: 0;
}

:local .ListItem {
  display: flex;
}

:local .Day {
  flex: 1 1 50%;
}

:local .Time {
  flex: 1 1 50%;
}